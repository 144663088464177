import logo from './logo.svg';
import './App.css';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
  MailIcon, PhoneIcon
} from '@heroicons/react/outline'




import { CheckIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Company', href: '#' },
]

const features = [
  {
    name: 'Data Science',
    description: "Do you have the data but don't know where to start? We can help you to ask the right questions and achieve the insights that you require.",
    icon: CloudUploadIcon,
  },
  {
    name: 'Cutting Edge Development',
    description: "Do you wish for something more than a report and a spreadsheet? We can turn that into a reality and create bespoke software for your team.",
    icon: LockClosedIcon,
  },
  {
    name: 'ML/AI',
    description: "Do you run everything on premises? Do you look enviously at the cloud and wonder how you can get your operation into it? Our expertise can turn that into a reality.",
    icon: RefreshIcon,
  }
]

const features2 = [
  {
    name: 'Invite team members',
    description: 'Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus.',
  },
  {
    name: 'Notifications',
    description: 'Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.',
  },
  {
    name: 'List view',
    description: 'Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.',
  },
  {
    name: 'Boards',
    description: 'Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis.',
  },
  {
    name: 'Keyboard shortcuts',
    description: 'Ullamcorper in ipsum ac feugiat. Senectus at aliquam vulputate mollis nec. In at risus odio.',
  },
  {
    name: 'Reporting',
    description: 'Magna a vel sagittis aliquam eu amet. Et lorem auctor quam nunc odio. Sed bibendum.',
  },
  {
    name: 'Calendars',
    description: 'Sed mi, dapibus turpis orci posuere integer. A porta viverra posuere adipiscing turpis.',
  },
  {
    name: 'Mobile app',
    description: 'Quisque sapien nunc nisl eros. Facilisis sagittis maecenas id dignissim tristique proin sed.',
  },
]


export default function App() {
  return (
    <div className="relative overflow-hidden">
      <div className="relative pt-6">
        <Popover>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="text-orange-500 mx-auto w-fit">
              <img
                className="h-12 sm:h-16"
                src="cytowide.png"
                alt=""
              />
            </div>
            {/* <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#">
                    <span className="sr-only">Cyto Software</span>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src="cytowide.png"
                      alt=""
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:space-x-10">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="border-transparent border-b-2 font-medium text-gray-400 hover:text-gray-200 hover:border-orange-500">
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                  <a
                    href="#"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </span>
              </div>
            </nav> */}
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src="logo-symbol.png"
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {/* <a
                  href="#"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                >
                  Log in
                </a> */}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <main className="pt-8 bg-white mx-auto max-w-7xl px-4 mt-3 sm:mt-6 rounded-none xl:rounded-3xl">
        <div className="text-center">
            {/* <img src="logo-nobg.png" className="mb-5 h-48 mx-auto my-auto" /> */}
            <h1 className="text-4xl tracking-tight font-semibold text-gray-600 sm:text-5xl md:text-6xl pt-2">
              <span className="block xl:inline">Developing value through</span>{' '}
              <span className="block text-orange-500 xl:inline">innovation</span>
            </h1>
            {/* <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            We are a team of software developers and data scientists building applications at the cutting edge of development. With expertise in the gaming industry we provide software solutions that leverage our ML/AI capability to meet our clients needs. With a track record of working for some of the biggest clients in the industry you can have the confidence that our team can deliver results for you. 
            </p> */}
          </div>
          
          <div className="relative bg-white py-4 sm:py-4 lg:py-6">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              {/* <h2 className="text-base font-semibold uppercase tracking-wider text-orange-600">Driven by Data Science</h2> */}
              {/* <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Data Science for your business
              </p> */}
              <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500 leading-8">
                We are a team of software developers and data scientists building applications at the cutting edge of development. With expertise in the gaming industry we provide software solutions that leverage our ML/AI capability to meet our clients needs. With a track record of working for some of the biggest clients in the industry you can have the confidence that our team can deliver results for you. 
              </p>
              {/* <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center rounded-md bg-orange-500 p-3 shadow-lg">
                              <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                          <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
{/* 
          <div className="bg-white">
            <div className="max-w-7xl mx-auto pb-8 px-4 sm:px-6 lg:pb-20 lg:px-8">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold text-gray-900">All-in-one platform</h2>
                <p className="mt-4 text-lg text-gray-500">
                  Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
                </p>
              </div>
              <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                {features2.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div> */}

          <div className="max-w-7xl mx-auto py-0 pb-10 px-4 mt-5">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div>
                <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Contact Us</h2>
                <div className="mt-3">
                  <p className="text-lg text-gray-500">
                    We’d love to hear from you!
                  </p>
                </div>
                <div className="mt-9 md:grid md:grid-cols-2 md:gap-8 max-w-2xl  mx-auto">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 text-base text-gray-500 text-left">
                      <p><span className='font-semibold'>Cyto Software Ltd</span><br />43-45 Devizes Road, Swindon, Wiltshire, United Kingdom, SN1 4BG</p>
                    </div>
                  </div>
                  <div className="flex mt-3 md:mt-0">
                    <div className="flex-shrink-0">
                      <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 text-base text-gray-500">
                      <p>support@cytosoftware.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer class="w-full max-w-container mx-auto py-6 text-center text-sm text-gray-500 sm:flex sm:items-center sm:justify-center">
          <p>&copy; 2022 Cyto Software Ltd. All rights reserved.</p>
        </footer>
      </div>
    </div>
  )
}
